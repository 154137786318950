<template>
  <div class="one-box flex flex-d" style="position: relative;">
    <headers :times="headTime" languageType="EN"></headers>
    <Subhead size="large" languageType="EN"></Subhead>
    <div class="main flex flex-d flex-1 ai-c">
      <div class="money flex flex-d jc-sb">
        <div class="title-view flex jc-sb ai-c">
          <p class="title">The GMV of 11.11 Online Shopping Festival (Yuan)</p>
          <div class="title-time">{{ data.time |dateFormat }}</div>
        </div>
        <Counter :num="data.sales"></Counter>
      </div>
      <div class="sub-money flex jc-sb">
        <!-- 包裹数 -->
        <div class="sub-money-item flex flex-d ai-c jc-sb">
          <p class="title">Total Packages</p>
          <CounterStyleMin :num="data.packageNum"></CounterStyleMin>
        </div>
        <!-- 平均价 -->
        <div class="sub-money-item flex flex-d ai-c jc-sb">
          <p class="title">Average Transaction Value （Yuan）</p>
          <CounterStyleMin :num="data.pct"></CounterStyleMin>
        </div>
      </div>
    </div>
    <Annotation languageType="EN"></Annotation>
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'
import TitleAndTime from '@/components/Big/TitleAndTime'
import Annotation from '@/components/Big/Annotation'
import Subhead from '@/components/Big/Subhead'
import Counter from '@/components/Big/Counter.vue'
import CounterStyleMin from '@/components/Big/CounterStyleMin.vue'
export default {
  components: {
    Headers,
    Annotation,
    Subhead,
    TitleAndTime,
    Counter,
    CounterStyleMin,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          sales: '0',
          packageNum: '0',
          pct: '0',
        }
      },
    },
  },
  data() {
    return {
      headTime: '',
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/defaultEn.scss';
.one-box {
  width: 100%;
  height: 100%;
  font-size: rem(24);
  background: url('../../../../assets/speaial_activity_img/image/one_bg.png')
    no-repeat center;
  background-size: 100% 100%;
  overflow: hidden;
}
</style>
